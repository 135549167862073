import Layout from 'containers/Layout';
import { useRouter } from 'next/router';
import { FC } from 'react';
// import Banner from 'containers/home/components/Banner';
import Container from '@mui/material/Container';
import { styled } from '@mui/material/styles';
import StripesBackground from 'components/atoms/StripesBackground';
import { HomeContainerProps } from 'pages';
import { AboutUs } from './AboutUs';
import { HowItWorksSection } from './how-it-works';
import SeoLinksSection from './seo-links';
// import uniqBy from 'lodash/uniqBy';
// import { useModels } from '../../services/auction/hooks/useModels';
// import { useAuctionsCountByModel } from '../../services/auction/hooks/useAuctionsCountByModel';
import { GoogleReviews } from 'components/organisms/GoogleReviews/GoogleReviews';
import { posts } from 'containers/blog';
import { useRecoilValue } from 'recoil';
import { getGoogleReviewsAtom } from 'recoil/atoms';
import {
  ARE_GOOGLE_REVIEWS_ENABLED,
  AVAILABLE_MONTHLY_PAYMENTS,
} from 'shared/utils/constants';
import { Benefits } from './Benefits';
import { BlogSection } from './Blog';
import { BrandsSection } from './brands';
import { MediaMentions } from './components/MediaMentions';
import SearchBar from './components/SearchBar';
import { Testimonials } from './components/Testimonials';
// import PopularSearchOptions from './PopularSearchOptions';
import Box from '@mui/material/Box';
import { SubscriberForm } from 'components/organisms/SubscribeForm';
import { isAuthorizedSelector } from 'containers/deal/recoil/selectors';
import { CustomerFeedback } from './components/CustomerFeedback';

const Section = styled('section')(({ theme }) => ({
  paddingTop: theme.spacing(4),
  paddingBottom: theme.spacing(6),
}));

export const WhiteBg = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
}));

export const ReviewsSection = styled('section')(({ theme }) => ({
  padding: theme.spacing(6, 0, 2),
  [theme.breakpoints.down('xs')]: {
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(0),
  },
}));

export const BenefitsSection = styled('section')(({ theme }) => ({
  padding: theme.spacing(4, 0, 8),
}));

export const QuoteBenefitsSection = styled('section')(({ theme }) => ({
  padding: theme.spacing(4, 0, 0),
}));

export const OptionsSection = styled('section')(({ theme }) => ({
  marginTop: theme.spacing(-6),
  padding: theme.spacing(0, 0, 4),
}));

export const HowItWorksSectionWrapper = styled('section')(({ theme }) => ({
  marginTop: theme.spacing(-6),
  padding: theme.spacing(0, 0, 2),
}));

export const BrandsSectionWrapper = styled('section')(({ theme }) => ({
  marginTop: theme.spacing(6),
  marginBottom: theme.spacing(6),
}));

export const AboutUsSection = styled('section')(({ theme }) => ({
  marginTop: theme.spacing(-2),
  paddingBottom: theme.spacing(4),
  [theme.breakpoints.down('xs')]: {
    paddingTop: theme.spacing(0),
    marginTop: theme.spacing(-10),
    paddingBottom: theme.spacing(4),
  },
}));

export const BlogSectionWrapper = styled('section')(({ theme }) => ({
  marginBlock: theme.spacing(6),
  padding: theme.spacing(0, 0, 2),
}));

import decorativeTrianglesBackground from 'public/decorative-triangles-background.webp';

export const TrianglePatternBackground = styled('div')(() => ({
  position: 'relative',
  '&::after': {
    content: '""',
    position: 'absolute',
    overflow: 'hidden',
    height: '100%',
    width: '100%',
    top: 0,
    zIndex: -1,
    backgroundColor: '#FAFAFA',
    backgroundImage: `url(${decorativeTrianglesBackground.src})`,
    backgroundPosition: 'top',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    filter: 'opacity(0.8)',
    '-ms-filter': 'opacity(0.8)',
  },
}));

export const TrianglePatternNewBackground = styled('div')(() => ({
  position: 'relative',
  '&::after': {
    content: '""',
    position: 'absolute',
    overflow: 'hidden',
    height: '100%',
    width: '100%',
    top: 0,
    zIndex: -1,
    backgroundImage: `url(${decorativeTrianglesBackground.src})`,
    backgroundPosition: 'top',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    filter: 'opacity(0.6)',
    '-ms-filter': 'opacity(0.6)',
  },
}));

export const GreyBg = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.grey[100],
}));

export type QueryType = {
  make?: string;
  model?: string;
  minMonthlyPayment?: number;
  maxMonthlyPayment?: number;
  body_style?: string;
  zipcode?: string;
};

export const HomeContainer: FC<HomeContainerProps> = () => {
  const router = useRouter();
  // const [selectedMakeId, setSelectedMakeId] = useState<number>();
  const reviews = useRecoilValue(getGoogleReviewsAtom);
  const isAuthorized = useRecoilValue(isAuthorizedSelector);

  const onSubmit = ({ makeName, modelName, budget, bodyStyle, zipcode }) => {
    const query: QueryType = {};
    if (makeName) {
      query.make = makeName;
    }
    if (modelName) {
      query.model = modelName;
    }
    if (budget != null && budget !== '') {
      const { min, max } = AVAILABLE_MONTHLY_PAYMENTS[budget] || {};

      if (Number.isFinite(Number(min))) {
        query.minMonthlyPayment = Number(min);
      }
      if (Number.isFinite(Number(max))) {
        query.maxMonthlyPayment = Number(max);
      }
    }
    if (bodyStyle) {
      query.body_style = bodyStyle;
    }
    if (zipcode) {
      query.zipcode = zipcode;
    }
    router.push({
      pathname: '/deals',
      query,
    });
  };

  // const { models } = useModels(selectedMakeId);
  // const { auctionsCountByModel } = useAuctionsCountByModel(selectedMakeId);

  // const onMakeChange = async (makeId: string) => {
  //   if (makeId && makeId !== '-1') {
  //     setSelectedMakeId(parseInt(makeId));
  //   } else {
  //     setSelectedMakeId(undefined);
  //   }
  // };

  const areThereAnyGoogleReviews = reviews?.length > 0;

  return (
    <Layout>
      <WhiteBg>
        <SearchBar onSubmit={onSubmit} />
        {/* <Banner
          makes={makes}
          auctionCountByMakes={auctionCountByMakes}
          models={uniqBy(models.valueMaybe() || [], (model) => model.name)}
          auctionsCountByModels={auctionsCountByModel.valueMaybe() || []}
          onSubmit={onSubmit}
          onMakeChange={onMakeChange}
        /> */}
      </WhiteBg>
      <TrianglePatternNewBackground>
        <Container maxWidth='md'>
          <BenefitsSection>
            <Benefits />
          </BenefitsSection>
        </Container>
      </TrianglePatternNewBackground>
      <StripesBackground sx={{ marginTop: 0, paddingTop: 6, paddingBottom: 4 }}>
        <CustomerFeedback />
      </StripesBackground>
      {/* <StripesBackground>
        <Container maxWidth='lg'>
          <OptionsSection>
            <PopularSearchOptions />
          </OptionsSection>
        </Container>
      </StripesBackground> */}
      <GreyBg>
        <Container maxWidth='lg'>
          <BrandsSectionWrapper>
            <BrandsSection />
          </BrandsSectionWrapper>
        </Container>
      </GreyBg>
      <WhiteBg sx={{ overflow: 'hidden' }}>
        <Container maxWidth='lg'>
          <HowItWorksSectionWrapper>
            <HowItWorksSection />
          </HowItWorksSectionWrapper>
        </Container>
      </WhiteBg>
      <WhiteBg>
        <Container maxWidth='lg'>
          {!isAuthorized && (
            <Box pb={10} mb={10}>
              <SubscriberForm />
            </Box>
          )}
        </Container>
      </WhiteBg>
      <StripesBackground>
        <Container maxWidth='lg'>
          <AboutUsSection>
            <AboutUs />
          </AboutUsSection>
        </Container>
      </StripesBackground>
      <WhiteBg>
        <MediaMentions />
      </WhiteBg>
      <GreyBg>
        <Testimonials />
      </GreyBg>
      <WhiteBg>
        <Container maxWidth='lg'>
          <Section>
            <SeoLinksSection />
          </Section>
        </Container>
      </WhiteBg>
      {ARE_GOOGLE_REVIEWS_ENABLED && areThereAnyGoogleReviews && (
        <TrianglePatternBackground>
          <Container maxWidth='lg'>
            <ReviewsSection>
              <GoogleReviews />
            </ReviewsSection>
          </Container>
        </TrianglePatternBackground>
      )}
      <WhiteBg>
        <Container maxWidth='lg'>
          <BlogSectionWrapper>
            <BlogSection
              isShowMore={true}
              posts={posts
                .sort((a, b) => {
                  return (
                    new Date(b.date).getTime() - new Date(a.date).getTime()
                  );
                })
                .slice(0, 3)}
            />
          </BlogSectionWrapper>
        </Container>
      </WhiteBg>
    </Layout>
  );
};
