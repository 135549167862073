import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import { SeoLinksGrid } from 'components/molecules/SeoLinksGrid';
import { useSetRecoilState } from 'recoil';
import { useFilters } from 'services/filters/hooks/useFilters';
import { filterZipcodeAtom } from 'services/zipcode/recoil';
import {
  locationsLinks,
  makesModelsLinks,
  statesLinks,
} from '../../shared/utils/seoLinksData';

const Content = styled('div')(() => ({
  overflow: 'hidden',
}));

const Title = styled(Typography)(() => ({
  '&.MuiTypography-root': {
    fontSize: '1.25rem',
  },
}));

export default function SeoLinks(): JSX.Element {
  const { setFilterValue } = useFilters();
  const setZipcodeAtom = useSetRecoilState(filterZipcodeAtom);
  const linkCliked = (url: string) => {
    const zipcode = url.match(/zipcode=\d{5}/);

    if (zipcode[0]) {
      const zipcodeNumbers = zipcode[0].substring(zipcode[0].length - 5);
      setFilterValue('zipcode', zipcodeNumbers);
      setZipcodeAtom(zipcodeNumbers);
    }
  };
  return (
    <>
      <Title className={'js-links-makes-&-models'} variant='h2' gutterBottom>
        Find Best Deal - Top Makes & Models:
      </Title>
      <Content>
        <SeoLinksGrid
          links={makesModelsLinks}
          classSuffix='makes-&-models'
          formatName={(name) => `Lease ${name}`}
        ></SeoLinksGrid>
      </Content>
      <br></br>
      <Title className={'js-links-locations'} variant='h2' gutterBottom>
        Leasing & Financing Deals Near You:
      </Title>
      <Content>
        <SeoLinksGrid
          links={locationsLinks}
          classSuffix='locations'
          formatName={(name) => `Lease New Car In ${name}`}
          onLinkClick={linkCliked}
        ></SeoLinksGrid>
      </Content>
      <br />
      <br />
      <Title className={'js-links-states'} variant='h2' gutterBottom>
        Leasing & Financing Deals by State:
      </Title>
      <Content>
        <SeoLinksGrid
          links={statesLinks}
          classSuffix='states'
          formatName={(name) => `${name} Leasing Deals`}
        />
      </Content>
    </>
  );
}
