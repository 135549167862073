import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import { FC } from 'react';
import { useSwiperReactive } from './hooks/useSwiperReactive';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { StandardLonghandProperties } from 'csstype';

const MediaItemContainer = styled('div')(({ theme }) => ({
  position: 'relative',
  width: '376px',
  height: '140px',
  [theme.breakpoints.down('md')]: {
    width: '188px',
    height: '109px',
  },
}));

const MediaItemWrapper = styled(Paper, {
  shouldForwardProp: (prop) => prop !== 'isActive' && prop !== 'relativeIndex',
})<{ isActive: boolean; relativeIndex: number }>(
  ({ theme, isActive, relativeIndex }) => {
    const factor = Math.abs(relativeIndex);
    let left: StandardLonghandProperties['left'] = '-50%';
    if (relativeIndex < 0) {
      left = `calc(-50% + ${theme.spacing(2.5)} * ${factor - 1})`;
    } else if (relativeIndex > 0) {
      left = `calc(-50% - ${theme.spacing(2.5)} * ${factor - 1})`;
    }
    return {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: `translate(${left}, -50%)`,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: isActive ? '456px' : '376px',
      height: isActive ? '140px' : '100.0px',
      transition: theme.transitions.create(
        ['width', 'height', 'filter', 'opacity', 'transform'],
        {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.short,
        },
      ),
      cursor: 'pointer',
      borderRadius: '20px',
      ...(!isActive && {
        filter: 'grayscale(0.5)',
        opacity: 0.5,
        cursor: 'default',
      }),
      [theme.breakpoints.down('md')]: {
        width: isActive ? '228px' : '188px',
        height: isActive ? '109px' : '90px',
        borderRadius: '20px',
      },
    };
  },
);

const MediaItemImageWrapper = styled('div')(({ theme }) => ({
  position: 'relative',
  height: `calc(100% - ${theme.spacing(3 * 2)})`,
  width: `calc(100% - ${theme.spacing(11 * 2)})`,
  [theme.breakpoints.down('md')]: {
    height: `calc(100% - ${theme.spacing(2.5 * 2)})`,
    width: `calc(100% - ${theme.spacing(3.75 * 2)})`,
  },
}));

type MediaItemProps = {
  image: string;
  url: string;
  index: number;
};

export const MediaItem: FC<MediaItemProps> = ({ image, url, index }) => {
  const router = useRouter();
  const swiper = useSwiperReactive();
  const isActive = swiper?.realIndex === index;

  let relativeIndex = index - swiper?.realIndex;
  if (relativeIndex > Math.floor(swiper?.slides.length / 2)) {
    relativeIndex = relativeIndex - swiper?.slides.length;
  } else if (relativeIndex < -Math.floor(swiper?.slides.length / 2)) {
    relativeIndex = relativeIndex + swiper?.slides.length;
  }

  const handleOnClick = async () => {
    await router.push(url);
  };

  return (
    <MediaItemContainer {...(isActive && { onClick: handleOnClick })}>
      <MediaItemWrapper
        elevation={4}
        isActive={isActive}
        relativeIndex={relativeIndex}
      >
        <MediaItemImageWrapper>
          <Image
            src={image}
            alt={url}
            objectFit='contain'
            layout='fill'
            sizes='200px'
            quality={90}
          />
        </MediaItemImageWrapper>
      </MediaItemWrapper>
    </MediaItemContainer>
  );
};
