import { FC } from 'react';
import Container from '@mui/material/Container';
import { Caption } from './Caption';
import { CustomerFeedbackCarousel } from './CustomerFeedbackCarousel';
import Grid from '@mui/material/Grid';
import { NoSSR } from '../../../../components/atoms/NoSSR';

export const CustomerFeedback: FC = () => {
  return (
    <>
      <Container maxWidth='lg'>
        <Grid container spacing={0}>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            display='flex'
            justifyContent='center'
            alignItems='center'
          >
            <Caption />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <NoSSR>
              <CustomerFeedbackCarousel />
            </NoSSR>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};
