import { FC } from 'react';
import { useScreenSize } from '../../../../hooks/useScreenSize';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import {
  DividerLeftMui5,
  DividerMui5,
} from '../../../../components/atoms/Divider';

export const Caption: FC = () => {
  const { isSmallScreen, isExtraSmallScreen } = useScreenSize();
  const isCompact = isSmallScreen || isExtraSmallScreen;

  const title = <> Discover Your Perfect Ride</>;
  const caption = (
    <>
      Wondering which car is right for you? Top car experts share hands-on
      reviews and insider tips on the best deals in our catalogue.
    </>
  );

  if (isCompact) {
    return (
      <Box mt={6.5}>
        <Typography variant='h4' component='h2' align='center' gutterBottom>
          {title}
        </Typography>
        <Typography variant='body1' color='textSecondary' align='center'>
          {caption}
        </Typography>
        <DividerMui5
          sx={({ spacing }) => ({ margin: spacing(2, 'auto', 4, 'auto') })}
        />
      </Box>
    );
  }
  return (
    <Box maxWidth='300px' mx={2}>
      <Typography variant='h3' component='h2' align='left' gutterBottom>
        {title}
      </Typography>
      <DividerLeftMui5 />
      <Typography variant='body1' align='left' gutterBottom>
        {caption}
      </Typography>
    </Box>
  );
};
