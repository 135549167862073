import brandsCardAcura from 'public/img/brands/card/Acura.png';
import brandsCardAudi from 'public/img/brands/card/Audi.png';
import brandsCardBMW from 'public/img/brands/card/BMW.png';
import brandsCardBuick from 'public/img/brands/card/Buick.png';
import brandsCardCadillac from 'public/img/brands/card/Cadillac.png';
import brandsCardChevrolet from 'public/img/brands/card/Chevrolet.png';
import brandsCardChrysler from 'public/img/brands/card/Chrysler.png';
import brandsCardDodge from 'public/img/brands/card/Dodge.png';
import brandsCardFord from 'public/img/brands/card/Ford.png';
import brandsCardGMC from 'public/img/brands/card/GMC.png';
import brandsCardHonda from 'public/img/brands/card/Honda.png';
import brandsCardHyundai from 'public/img/brands/card/Hyundai.png';
import brandsCardInfiniti from 'public/img/brands/card/Infiniti.png';
import brandsCardJeep from 'public/img/brands/card/Jeep.png';
import brandsCardKia from 'public/img/brands/card/KIA.png';
import brandsCardLexus from 'public/img/brands/card/Lexus.png';
import brandsCardLincoln from 'public/img/brands/card/Lincoln.png';
import brandsCardMazda from 'public/img/brands/card/Mazda.png';
import brandsCardMercedes from 'public/img/brands/card/Mercedes.png';
import brandsCardMitsubishi from 'public/img/brands/card/Mitsubishi.png';
import brandsCardNissan from 'public/img/brands/card/Nissan.png';
import brandCardRAM from 'public/img/brands/card/RAM.png';
import brandsCardToyota from 'public/img/brands/card/Toyota.png';
import brandsCardVolkswagen from 'public/img/brands/card/Volkswagen.png';
import brandsCardVolvo from 'public/img/brands/card/Volvo.png';

export const POPULAR_MAKES = [
  {
    name: 'Toyota',
    src: brandsCardToyota.src,
    seo: {
      keyAttributes: 'innovation, reliability, and performance',
      model1: 'Camry',
      model2: 'RAV4',
      perfectFor:
        'those seeking a fuel-efficient and smooth ride in a midsize sedan',
      offering: 'versatility and adventure-ready capabilities as a compact SUV',
      knownFor: 'fuel efficiency, reliability, and advanced safety features',
    },
  },
  {
    name: 'Honda',
    src: brandsCardHonda.src,
    seo: {
      keyAttributes: 'reliability, efficiency, and innovative technology',
      model1: 'Accord',
      model2: 'CR-V',
      perfectFor:
        'those seeking a blend of fuel efficiency and premium comfort in a midsize sedan',
      offering:
        'practicality and cutting-edge safety features as a compact SUV',
      knownFor:
        'fuel efficiency, advanced safety features, and lasting reliability',
    },
  },
  {
    name: 'Hyundai',
    src: brandsCardHyundai.src,
    seo: {
      keyAttributes: 'innovation, reliability, and cutting-edge technology',
      model1: 'Sonata',
      model2: 'Tucson',
      perfectFor: 'those seeking a stylish and fuel-efficient midsize sedan',
      offering: 'versatility and advanced safety features as a compact SUV',
      knownFor: 'fuel efficiency, advanced technology, and stylish designs',
    },
  },
  {
    name: 'Ford',
    src: brandsCardFord.src,
    seo: {
      keyAttributes: 'innovation, performance, and dependability',
      model1: 'F-150',
      model2: 'Explorer',
      perfectFor:
        'those seeking unmatched towing capacity and rugged off-road performance in a full-size pickup truck',
      offering:
        'spaciousness and advanced technology as a versatile midsize SUV',
      knownFor: 'durability, performance, and advanced safety features',
    },
  },
  {
    name: 'Kia',
    src: brandsCardKia.src,
    seo: {
      keyAttributes: 'innovation, reliability, and affordability',
      model1: 'Sorento',
      model2: 'Forte',
      perfectFor:
        'those seeking a spacious and family-friendly midsize SUV with modern features',
      offering: 'fuel efficiency and stylish design in a compact sedan',
      knownFor:
        'advanced technology, impressive fuel efficiency, and stylish designs',
    },
  },
  {
    name: 'Jeep',
    src: brandsCardJeep.src,
    seo: {
      keyAttributes:
        'rugged performance, off-road capability, and iconic design',
      model1: 'Wrangler',
      model2: 'Grand Cherokee',
      perfectFor:
        'those seeking unmatched off-road prowess and adventure-ready feature',
      offering:
        'a blend of luxury, space, and advanced technology in a versatile SUV',
      knownFor:
        'legendary off-road capabilities, powerful performance, and advanced safety features',
    },
  },
  {
    name: 'Acura',
    src: brandsCardAcura.src,
    seo: {
      keyAttributes: 'luxury, performance, and innovative technology',
      model1: 'RDX',
      model2: 'TLX',
      perfectFor:
        'those seeking a stylish and versatile compact SUV with advanced safety features and a spacious interior',
      offering:
        'a thrilling driving experience with its powerful engine and precise handling',
      knownFor:
        'luxurious features, exceptional reliability, and cutting-edge technology',
    },
  },
  {
    name: 'Audi',
    src: brandsCardAudi.src,
    seo: {
      keyAttributes: 'innovation, luxury, and high-performance engineering',
      model1: 'A4',
      model2: 'Q5',
      perfectFor:
        'those seeking a premium sedan with advanced technology and agile handling',
      offering:
        'a blend of sophisticated style and versatile performance for families or outdoor enthusiasts',
      knownFor:
        'cutting-edge technology, exceptional craftsmanship, and unmatched driving experience',
    },
  },
  {
    name: 'Buick',
    src: brandsCardBuick.src,
    seo: {
      keyAttributes: '',
      model1: 'Enclave',
      model2: 'Encore',
      perfectFor: 'a perfect blend of luxury, comfort, and reliability',
      offering:
        'a compact size with impressive fuel efficiency and modern technology for urban drivers',
      knownFor: 'elegant design, premium materials, and smooth ride quality',
    },
  },
  {
    name: 'Cadillac',
    src: brandsCardCadillac.src,
    seo: {
      keyAttributes: 'luxury, performance, and cutting-edge technology',
      model1: 'Escalade',
      model2: 'CT5',
      perfectFor:
        'families and individuals seeking spaciousness and premium features with its commanding presence and advanced safety systems',
      offering:
        'a blend of dynamic performance and sophisticated design for those who appreciate a thrilling driving experience',
      knownFor:
        'exceptional comfort, powerful engines, and luxurious interiors',
    },
  },
  {
    name: 'Chevrolet',
    src: brandsCardChevrolet.src,
    seo: {
      keyAttributes:
        'a perfect blend of innovation, reliability, and performance',
      model1: 'Silverado',
      model2: 'Equinox',
      perfectFor:
        'those seeking a rugged and capable pickup truck that excels in towing and hauling',
      offering:
        'a spacious interior and excellent fuel efficiency for families and commuters alike',
      knownFor:
        'outstanding safety ratings, advanced technology features, and impressive performance',
    },
  },
  {
    name: 'Chrysler',
    src: brandsCardChrysler.src,
    seo: {
      keyAttributes:
        'a perfect blend of luxury, comfort, and innovative technology',
      model1: '300',
      model2: 'Pacifica',
      perfectFor:
        'those who desire a spacious sedan with a powerful engine and a refined interior',
      offering:
        'versatility and family-friendly features such as ample cargo space and advanced safety technologies',
      knownFor: 'stylish designs, comfort, and high-quality materials',
    },
  },
  {
    name: 'Dodge',
    src: brandsCardDodge.src,
    seo: {
      keyAttributes:
        'powerful performance, bold design, and innovative technology',
      model1: 'Charger',
      model2: 'Durango',
      perfectFor:
        'those seeking a thrilling driving experience with its muscle car heritage and impressive horsepower',
      offering:
        'spacious seating and towing capabilities for families and adventure seekers alike',
      knownFor:
        'performance, distinctive styling, and advanced safety features',
    },
  },
  {
    name: 'GMC',
    src: brandsCardGMC.src,
    seo: {
      keyAttributes:
        'rugged capability, reliability, and innovative technology',
      model1: 'Sierra 1500',
      model2: 'Terrain',
      perfectFor:
        'those seeking a powerful truck with excellent towing capacity and advanced safety features',
      offering:
        'a spacious and comfortable interior ideal for families and everyday commuting',
      knownFor: 'durability, advanced technology, and versatile performance',
    },
  },
  {
    name: 'Lincoln',
    src: brandsCardLincoln.src,
    seo: {
      keyAttributes: 'luxury, comfort, and advanced technology',
      model1: 'Navigator',
      model2: 'Aviator',
      perfectFor:
        'those who seek a spacious and opulent SUV with powerful performance and advanced safety features',
      offering: 'a blend of dynamic performance and high-end interior design',
      knownFor:
        'elegant design, innovative technology, and exceptional comfort',
    },
  },
  {
    name: 'Mazda',
    src: brandsCardMazda.src,
    seo: {
      keyAttributes: 'innovation, reliability, and exceptional performance',
      model1: 'CX-5',
      model2: '3',
      perfectFor:
        'those seeking a versatile SUV with a refined interior and superior driving dynamics',
      offering: 'sporty handling, sleek design, and impressive fuel efficiency',
      knownFor:
        'engaging driving experience, modern technology, and sleek design',
    },
  },
  {
    name: 'Mercedes-Benz',
    src: brandsCardMercedes.src,
    seo: {
      keyAttributes: 'luxury, innovation, and world-class performance',
      model1: 'C-Class',
      model2: 'GLE',
      perfectFor:
        'those seeking a refined driving experience with cutting-edge technology and elegant design',
      offering:
        'versatile performance and spacious comfort for families or adventure seekers',
      knownFor:
        'superior craftsmanship, advanced safety features, and remarkable engineering',
    },
  },
  {
    name: 'Mitsubishi',
    src: brandsCardMitsubishi.src,
    seo: {
      keyAttributes: 'reliability, innovation, and versatility',
      model1: 'Outlander',
      model2: 'Eclipse Cross',
      perfectFor: 'families seeking spaciousness and advanced safety features',
      offering:
        'a stylish design and impressive fuel efficiency for urban adventurers',
      knownFor: 'durability, advanced technology, and great value',
    },
  },
  {
    name: 'Nissan',
    src: brandsCardNissan.src,
    seo: {
      keyAttributes: 'innovation, reliability, and fuel efficiency',
      model1: 'Altima',
      model2: 'Rogue',
      perfectFor:
        'those seeking a comfortable midsize sedan with advanced technology and impressive fuel economy',
      offering:
        'spacious interiors, modern safety features, and all-weather capability',
      knownFor:
        'fuel efficiency, advanced safety features, and cutting-edge technology',
    },
  },
  {
    name: 'Ram',
    src: brandCardRAM.src,
    seo: {
      keyAttributes:
        'outstanding durability, powerful performance, and innovative technology',
      model1: '1500',
      model2: '2500',
      perfectFor:
        'those seeking a versatile pickup that excels in both work and play with its impressive towing capacity and spacious interior',
      offering: 'enhanced capability and ruggedness for heavier-duty tasks',
      knownFor:
        'exceptional towing and hauling capabilities, comfortable interiors, and advanced technology features',
    },
  },
  {
    name: 'Volkswagen',
    src: brandsCardVolkswagen.src,
    seo: {
      keyAttributes: 'innovation, reliability, and exceptional performance',
      model1: 'Golf',
      model2: 'Tiguan',
      perfectFor:
        'those seeking a versatile hatchback with spirited driving dynamics and practical features',
      offering: 'spaciousness and advanced safety technologies',
      knownFor:
        'impressive fuel efficiency, cutting-edge technology, and engaging driving experience',
    },
  },
  {
    name: 'Volvo',
    src: brandsCardVolvo.src,
    seo: {
      keyAttributes: 'safety, reliability, and Scandinavian design',
      model1: 'XC60',
      model2: 'S60',
      perfectFor:
        'families seeking a spacious and luxurious SUV with top-notch safety features',
      offering:
        'a sleek and dynamic driving experience with its efficient performance and advanced technology',
      knownFor: 'commitment to safety, comfort, and eco-friendly technology',
    },
  },
  {
    name: 'BMW',
    src: brandsCardBMW.src,
    seo: {
      keyAttributes:
        'precision engineering, luxury, and exhilarating performance',
      model1: 'BMW 3 Series',
      model2: 'X5',
      perfectFor: 'those seeking a sporty and agile luxury sedan',
      offering:
        'a combination of spacious versatility and cutting-edge technology as a premium SUV',
      knownFor:
        'dynamic handling, innovative technology, and sophisticated design',
    },
  },
  {
    name: 'Lexus',
    src: brandsCardLexus.src,
    seo: {
      keyAttributes: 'innovation, elegance, and unparalleled comfort',
      model1: 'RX',
      model2: 'ES',
      perfectFor: 'those seeking a luxurious and reliable SUV',
      offering:
        'a smooth ride, sophisticated design, and advanced technology as a premium sedan',
      knownFor:
        'smooth ride, cutting-edge hybrid technology, and unmatched reliability',
    },
  },
  {
    name: 'INFINITI',
    src: brandsCardInfiniti.src,
    seo: {
      keyAttributes:
        'bold design, advanced technology, and dynamic performance',
      model1: 'Q50',
      model2: 'QX60',
      perfectFor: 'those seeking a sleek and powerful luxury sedan',
      offering:
        'spacious comfort and refined style for families or adventurers',
      knownFor:
        'powerful engines, striking design, and advanced driver-assist features',
    },
  },
];
