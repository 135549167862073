import { FC } from 'react';
import Container from '@mui/material/Container';
import { Caption } from './Caption';
import { BloggersCarousel } from './BloggersCarousel';
import Grid from '@mui/material/Grid';
import { NoSSR } from '../../../../components/atoms/NoSSR';
import { useScreenSize } from '../../../../hooks/useScreenSize';

export const Testimonials: FC = () => {
  const { isExtraSmallScreen, isSmallScreen } = useScreenSize();
  const isCompact = isSmallScreen || isExtraSmallScreen;
  return (
    <>
      <Container maxWidth='lg'>
        <Grid container spacing={0}>
          <Grid
            item
            xs={12}
            sm={12}
            md={4}
            lg={4}
            display='flex'
            justifyContent={!isCompact ? 'left' : 'center'}
            alignItems='center'
          >
            <Caption />
          </Grid>
          <Grid item xs={12} sm={12} md={8} lg={8}>
            <NoSSR>
              <BloggersCarousel />
            </NoSSR>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};
