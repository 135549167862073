import { FC } from 'react';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { DividerMui5 } from '../../../../components/atoms/Divider';
import Box from '@mui/material/Box';
import { BlogsCarousel } from './BlogsCarousel';

export const MediaMentions: FC = () => {
  return (
    <>
      <Container maxWidth='sm'>
        <Box mt={6.5}>
          <Typography variant='h4' component='h2' align='center' gutterBottom>
            As Featured in
          </Typography>
          <Typography
            variant='body1'
            color='textSecondary'
            textTransform='uppercase'
            fontWeight='600'
            align='center'
          >
            Check out where we&apos;ve been making headlines in the media.
          </Typography>
          <DividerMui5
            sx={({ spacing }) => ({ margin: spacing(2, 'auto', 4, 'auto') })}
          />
        </Box>
      </Container>
      <Container maxWidth='lg'>
        <BlogsCarousel />
      </Container>
    </>
  );
};
