import { PlatformIcon } from '../Testimonials/constants';

export const CUSTOMERS_LIST: Array<{
  name: string;
  platform: PlatformIcon;
  description: string;
  video: string;
}> = [
  {
    platform: PlatformIcon.Youtube,
    name: 'Jerome L.',
    description: '"I’ve saved over $4,5K on my first car!"',
    video: 'https://www.youtube.com/shorts/5JnQ7Fg8RcE',
  },
  {
    platform: PlatformIcon.Youtube,
    name: 'Patrick J.',
    description:
      '"Got the car I wanted, not just what I could afford - no hidden fees, full transparency"',
    video: 'https://www.youtube.com/shorts/0s9ZFkvLBbQ',
  },
  {
    platform: PlatformIcon.Youtube,
    name: 'Mo H.',
    description:
      '"I’ve saved over 12K compared to dealership offers. And they did all the negotiating for me”',
    video: 'https://www.youtube.com/shorts/bweK44c4gZw',
  },
  {
    platform: PlatformIcon.Youtube,
    name: 'Drake B.',
    description: `"The only time I stepped into a dealership? To pick up my car they've made it that easy."'`,
    video: 'https://www.youtube.com/shorts/rSe9TB_lVno',
  },
];
