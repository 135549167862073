export enum PlatformIcon {
  Youtube = 'YouTube',
  TikTok = 'TikTok',
  Instagram = 'Instagram',
}

export const BLOGGERS_LIST: Array<{
  name: string;
  platform: PlatformIcon;
  description: string;
  video: string;
  fallbackImage: string;
}> = [
  {
    name: 'Juliana',
    platform: PlatformIcon.Youtube,
    description:
      'Warning: Vehicle Warranties Exposed – What You Must Know First!',
    // description: 'With AutoBandit, the price you see is the price you pay!',
    video: 'https://www.youtube.com/shorts/QJOc9a7woA4',
    fallbackImage: '/img/bloggers/youtube_juliana.png',
  },
  {
    name: 'Juliana',
    platform: PlatformIcon.Youtube,
    description: 'Top 3 Car Buying Mistakes That Could Cost You Thousands!',
    // description: 'Leasing with no hidden fees? It’s real with AutoBandit!',
    video: 'https://www.youtube.com/shorts/8OUOxgJa0aE',
    fallbackImage: '/img/bloggers/youtube_juliana_2.png',
  },
  {
    name: 'Juliana',
    platform: PlatformIcon.Youtube,
    description:
      "Boost Your Car's Resale Value Before Selling: Top 3 Expert Tips",
    video: 'https://www.youtube.com/shorts/baU0vE_Qv00',
    fallbackImage: '/img/bloggers/youtube_juliana_3.png',
  },
  // {
  //   name: 'Jana',
  //   platform: PlatformIcon.TikTok,
  //   description: 'Top cars for mamas on-the-go are waiting for you!',
  //   video:
  //     'https://www.tiktok.com/@realmomcartours/video/7455412930943782187?_t=ZT-8slEOpll5Ss&_r=1',
  // },
  // {
  //   name: 'Jana',
  //   platform: PlatformIcon.Instagram,
  //   description: '',
  //   video: 'https://www.instagram.com/reel/DEVfMBKx45i/?igsh=MjRtcnFvZHF6NDU2',
  // },
  {
    name: 'Jana',
    platform: PlatformIcon.Youtube,
    description: 'My top 5 list - what do you think?',
    video: 'https://youtu.be/oTmLHK_TXr4?si=dinSs43ZLMAx4aK5',
    fallbackImage: '/img/bloggers/youtube_jana.png',
  },
  {
    name: 'Rad Cars',
    platform: PlatformIcon.Instagram,
    // description: 'Get your dream car—negotiated simply for you!',
    description:
      'Autobandit has some CRAZY lease deals available right now. Best of all, no dealing with dealerships or salespeople.',
    video: 'https://www.instagram.com/p/DESu7jNyO1q/',
    fallbackImage: '/img/bloggers/instagram_rad_cars.png',
  },
];
