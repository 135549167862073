import imgTestimonialsBlogsAP from 'public/img/testimonials/blogs/AP.png';
import imgTestimonialsBlogsAppleNewsPlus from 'public/img/testimonials/blogs/AppleNewsPlus.png';
import imgTestimonialsBlogsBusinessInsider from 'public/img/testimonials/blogs/BusinessInsider.png';
import imgTestimonialsBlogsLAWeekly from 'public/img/testimonials/blogs/LAWeekly.png';
import imgTestimonialsBlogsUSReporter from 'public/img/testimonials/blogs/USReporter.png';

export const BLOGS_LIST: Array<{
  image: string;
  url: string;
}> = [
  {
    image: imgTestimonialsBlogsAP.src,
    url: 'https://apnews.com/press-release/kisspr/california-94e6fbf9bae02ff9f4b9f803aaf88fb0',
  },
  {
    image: imgTestimonialsBlogsLAWeekly.src,
    url: 'https://www.laweekly.com/why-autobandit-is-the-future-of-car-leasing-for-digital-first-consumers/',
  },
  {
    image: imgTestimonialsBlogsUSReporter.src,
    url: 'https://usreporter.com/autobandit-launches-comprehensive-online-platform/',
  },
  {
    image: imgTestimonialsBlogsAppleNewsPlus.src,
    url: 'https://apple.news/AQZtMbk4tRUOt-7rx0v6NXQ',
  },
  {
    image: imgTestimonialsBlogsBusinessInsider.src,
    url: 'https://markets.businessinsider.com/news/stocks/introducing-autobandit-the-online-car-leasing-and-financing-platform-that-changes-the-game-in-car-leasing-1034294187',
  },
];
