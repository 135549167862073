import { styled } from '@mui/material';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Navigation, Pagination } from 'swiper/modules';
import { FC, useMemo, useRef } from 'react';
import { useScreenSize } from '../../../../hooks/useScreenSize';
import type { Swiper as SwiperClass } from 'swiper/types';
import { shuffle } from '../MediaMentions/utils';
import ButtonBase from '@mui/material/ButtonBase';
import {
  LeftNavIconPure,
  RightNavIconPure,
} from '../../../../components/organisms/GoogleReviews/CarouselNav';
import { BLOGGERS_LIST } from './constants';
import { MediaItem } from './MediaItem';
import { StandardLonghandProperties } from 'csstype';
import { NoSSR } from '../../../../components/atoms/NoSSR';

const StyledSwiper = styled(Swiper, {
  shouldForwardProp: (prop) => prop !== 'isCompact',
})<{
  isCompact?: boolean;
}>(({ theme }) => {
  const mb: StandardLonghandProperties['marginBottom'] = theme.spacing(11.25);
  const pl: StandardLonghandProperties['paddingLeft'] = theme.spacing(9.7);
  return {
    '&.swiper': {
      '& .swiper-pagination': {
        // eslint-disable-next-line prettier/prettier
        bottom: `calc(${mb} / 2 - var(--swiper-navigation-size) / 2)`,
        height: 'var(--swiper-navigation-size) !important',
        lineHeight: 'var(--swiper-navigation-size) !important',
        paddingLeft: pl,
        textAlign: 'left',
        [theme.breakpoints.down('md')]: {
          paddingLeft: 'unset',
          textAlign: 'center',
        },
        '& .swiper-pagination-bullet ': {
          width: 10,
          height: 10,
          borderRadius: '50%',
          backgroundColor: theme.palette.primary.main,
        },
      },
    },
    '& .swiper-wrapper': {
      marginTop: theme.spacing(7),
      marginBottom: mb,
      '& .swiper-slide': {
        display: 'flex',
        alignItems: 'center',
        width: 'fit-content',
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
      },
      [theme.breakpoints.down('md')]: {
        marginTop: 0,
      },
    },
    '& .swiper-button-prev, & .swiper-button-next': {
      '&::after': {
        content: '""',
        display: 'none',
      },
      // eslint-disable-next-line prettier/prettier
      bottom: `calc(${mb} / 2 - var(--swiper-navigation-size) / 2)`,
      top: 'unset',
      [theme.breakpoints.down('md')]: {
        top: `calc(var(--swiper-navigation-size) / 2 + 186.5px - calc(var(--swiper-navigation-size) / 2))`,
        bottom: 'unset',
      },
    },
    '& .swiper-button-prev': {
      left: `calc(0% - calc(var(--swiper-navigation-size) / 44 * 27 / 2) + ${pl} - 30px)`,
      [theme.breakpoints.down('md')]: {
        left: `calc(50% - calc(var(--swiper-navigation-size) / 44 * 27 / 2) - 140px)`,
      },
    },
    '& .swiper-button-next': {
      right: `calc(100% - calc(var(--swiper-navigation-size) / 44 * 27 / 2) - ${pl} - 118px)`,
      [theme.breakpoints.down('md')]: {
        right: `calc(50% - calc(var(--swiper-navigation-size) / 44 * 27 / 2) - 140px)`,
      },
    },
  };
});

export const BloggersCarousel: FC = () => {
  // hack to reinitialize component and to not break styles on breakpoints
  const { isSmallScreen, isExtraSmallScreen } = useScreenSize();
  const isCompact = isSmallScreen || isExtraSmallScreen;
  if (isCompact) {
    return <Carousel key={new Date().getTime()} />;
  }
  return <Carousel key={new Date().getTime()} />;
};

export const Carousel: FC = () => {
  const { isSmallScreen, isExtraSmallScreen } = useScreenSize();
  const isCompact = isSmallScreen || isExtraSmallScreen;
  const swiperRef = useRef<SwiperClass>(null);
  const bloggers = useMemo(() => shuffle(BLOGGERS_LIST), []);
  return (
    <NoSSR>
      <StyledSwiper
        loop
        rewind={true}
        autoplay={{
          delay: 10_000,
          pauseOnMouseEnter: true,
          waitForTransition: true,
        }}
        {...(isCompact && { centeredSlides: true })}
        slidesPerView={'auto'}
        pagination={{ clickable: true }}
        modules={[Navigation, Pagination, Autoplay]}
        isCompact={isCompact}
        spaceBetween={0}
        onBeforeInit={(swiper: SwiperClass) => {
          swiperRef.current = swiper;
        }}
        style={{
          '--swiper-navigation-size': '44px',
        }}
      >
        <div className='swiper-wrapper'>
          {bloggers.map((media, i) => (
            <SwiperSlide key={`${media.video} ${i}`}>
              <MediaItem
                name={media.name}
                description={media.description}
                platform={media.platform}
                video={media.video}
                fallbackImage={media.fallbackImage}
                index={i}
              />
            </SwiperSlide>
          ))}
        </div>
        <div
          className='swiper-button-prev'
          onClick={() => {
            swiperRef.current?.slidePrev();
          }}
        >
          <ButtonBase>
            <LeftNavIconPure />
          </ButtonBase>
        </div>
        <div
          className='swiper-button-next'
          onClick={() => {
            swiperRef.current?.slideNext();
          }}
        >
          <ButtonBase>
            <RightNavIconPure />
          </ButtonBase>
        </div>
      </StyledSwiper>
    </NoSSR>
  );
};
