import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { DividerMui5 } from 'components/atoms/Divider';
import Image from 'next/image';
import { FC } from 'react';
import { BRAND_NAME } from 'shared/utils/constants';
import { grey } from 'theme/colors';
import fastEasyIcon from '/public/img/icons/icon-fast-easy.svg';
import lowestPricesIcon from '/public/img/icons/icon-lowest-prices.svg';
import noHiddenFeesIcon from '/public/img/icons/icon-no-hidden-fees.svg';
import verifiedInventoryIcon from '/public/img/icons/icon-verified-inventory.svg';

const Icon = styled('div')(({ theme }) => ({
  marginBottom: theme.spacing(1),
  transition: 'filter 0.3s',
}));

const HowItWorksItem = styled('div')(() => ({
  textAlign: 'center',
  height: '100%',
}));

const Content = styled('div')(() => ({
  overflow: 'hidden',
}));

const StyledImage = styled(Image, {
  shouldForwardProp: (prop) => prop !== 'isBlured',
})<{ isBlured: boolean }>(({ isBlured }) => ({
  filter: isBlured ? 'blur(4px)' : '',
}));

const benefits = [
  {
    title: 'Lowest Prices & Flexible Financing',
    icon: lowestPricesIcon,
    alt: 'Prices',
    width: 106,
    height: 90,
  },
  {
    title: 'No hidden fees',
    icon: noHiddenFeesIcon,
    alt: 'Fees',
    width: 128,
    height: 90,
  },
  {
    title: 'Verified inventory',
    icon: verifiedInventoryIcon,
    alt: 'Verified Inventory',
    width: 138,
    height: 90,
  },
  {
    title: 'Fast and easy',
    icon: fastEasyIcon,
    alt: 'Fast and easy',
    width: 92,
    height: 90,
  },
];

type Props = {
  withTitle?: boolean;
};

export const Benefits: FC<Props> = ({ withTitle = true }) => {
  return (
    <>
      <Box
        color={grey[700]}
        mb={4}
        justifyContent='center'
        alignItems='center'
        display='flex'
        flexDirection='column'
      >
        {withTitle && (
          <>
            <Typography
              variant='h3'
              gutterBottom
              align='center'
              sx={(theme) => ({
                [theme.breakpoints.down('sm')]: {
                  typography: 'h4',
                },
              })}
            >
              {BRAND_NAME} Guarantees:
            </Typography>
            <DividerMui5 />
          </>
        )}
      </Box>
      <Content>
        <Grid container>
          {benefits.map((benefit) => (
            <Grid item lg={3} md={3} sm={6} xs={6} key={benefit.title}>
              <HowItWorksItem>
                <Icon>
                  <StyledImage
                    width={benefit.width}
                    height={benefit.height}
                    alt={benefit.alt}
                    src={benefit.icon}
                    isBlured={false}
                  />
                </Icon>
              </HowItWorksItem>
            </Grid>
          ))}
        </Grid>
      </Content>
    </>
  );
};
