import { styled } from '@mui/material';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Navigation, Pagination } from 'swiper/modules';
import { FC, useMemo, useRef } from 'react';
import type { Swiper as SwiperClass } from 'swiper/types';
import { shuffle } from '../MediaMentions/utils';
import ButtonBase from '@mui/material/ButtonBase';
import {
  LeftNavIconPure,
  RightNavIconPure,
} from '../../../../components/organisms/GoogleReviews/CarouselNav';
import { CUSTOMERS_LIST } from './constants';
import { MediaItem } from './MediaItem';
import { StandardLonghandProperties } from 'csstype';
import { NoSSR } from '../../../../components/atoms/NoSSR';

const StyledSwiper = styled(Swiper)(({ theme }) => {
  const mb: StandardLonghandProperties['marginBottom'] = theme.spacing(8.25);
  return {
    '&.swiper': {
      '& .swiper-pagination': {
        // eslint-disable-next-line prettier/prettier
        bottom: `calc(${mb} / 2 - var(--swiper-navigation-size) / 2)`,
        height: 'var(--swiper-navigation-size) !important',
        lineHeight: 'var(--swiper-navigation-size) !important',
        textAlign: 'center',
        '&.swiper-pagination-bullets-dynamic': {
          fontSize: 'unset !important',
        },
        '& .swiper-pagination-bullet ': {
          width: 10,
          height: 10,
          borderRadius: '50%',
          backgroundColor: theme.palette.primary.main,
        },
      },
    },
    '& .swiper-wrapper': {
      marginBottom: mb,
      '& .swiper-slide': {
        display: 'flex',
        alignItems: 'center',
        width: 'fit-content',
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
      },
    },
    '& .swiper-button-prev, & .swiper-button-next': {
      '&::after': {
        content: '""',
        display: 'none',
      },
      // eslint-disable-next-line prettier/prettier
      top: `calc(var(--swiper-navigation-size) / 2 + 186.5px - calc(var(--swiper-navigation-size) / 2))`,
      bottom: 'unset',
    },
    '& .swiper-button-prev': {
      left: `calc(50% - calc(var(--swiper-navigation-size) / 44 * 27 / 2) - 140px)`,
    },
    '& .swiper-button-next': {
      right: `calc(50% - calc(var(--swiper-navigation-size) / 44 * 27 / 2) - 140px)`,
    },
  };
});

export const CustomerFeedbackCarousel: FC = () => {
  const swiperRef = useRef<SwiperClass>(null);
  const customersList = useMemo(() => shuffle(CUSTOMERS_LIST), []);
  const customers = [...customersList, ...customersList];
  return (
    <NoSSR>
      <StyledSwiper
        loop
        rewind={true}
        autoplay={{
          delay: 10_000,
          pauseOnMouseEnter: true,
          waitForTransition: true,
        }}
        centeredSlides={true}
        slidesPerView={'auto'}
        pagination={{
          clickable: true,
          dynamicBullets: true,
        }}
        modules={[Navigation, Pagination, Autoplay]}
        spaceBetween={0}
        onBeforeInit={(swiper: SwiperClass) => {
          swiperRef.current = swiper;
        }}
        style={{
          '--swiper-navigation-size': '44px',
        }}
      >
        <div className='swiper-wrapper'>
          {customers.map((media, i) => (
            <SwiperSlide key={`${media.video} ${i}`}>
              <MediaItem
                name={media.name}
                description={media.description}
                platform={media.platform}
                video={media.video}
                index={i}
              />
            </SwiperSlide>
          ))}
        </div>
        <div
          className='swiper-button-prev'
          onClick={() => {
            swiperRef.current?.slidePrev();
          }}
        >
          <ButtonBase>
            <LeftNavIconPure />
          </ButtonBase>
        </div>
        <div
          className='swiper-button-next'
          onClick={() => {
            swiperRef.current?.slideNext();
          }}
        >
          <ButtonBase>
            <RightNavIconPure />
          </ButtonBase>
        </div>
      </StyledSwiper>
    </NoSSR>
  );
};
