import Box from '@mui/material/Box';
import CardActionArea from '@mui/material/CardActionArea';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { DividerMui5 } from 'components/atoms/Divider';
import NextLinkMui5 from 'components/atoms/NextLink';
import { ShowMoreButton } from 'components/atoms/ShowMoreButton';
import { useScreenSize } from 'hooks/useScreenSize';
import Image from 'next/image';
import { FC, useState } from 'react';
import { Subheader } from './components/common';
import { POPULAR_MAKES } from './popular-makes.utils';

type BrandsSectionProps = {
  showTitle?: boolean;
  showSubTitle?: boolean;
  excludedBrand?: string;
};

export const BrandsSection: FC<BrandsSectionProps> = ({
  showTitle = true,
  showSubTitle = false,
  excludedBrand = '',
}) => {
  const fullList = POPULAR_MAKES.filter(({ name }) => name !== excludedBrand);
  const shortListLength = 6;
  const [listLength, setListLength] = useState(shortListLength);
  const { isSmallScreen, isExtraSmallScreen } = useScreenSize();
  const isCompact = isSmallScreen || isExtraSmallScreen;

  return (
    <Box
      display={'flex'}
      flexDirection={'column'}
      alignItems={'center'}
      justifyContent={'center'}
    >
      {showTitle && (
        <>
          <Typography
            className='js-how-it-works'
            variant={isCompact ? 'h4' : 'h3'}
            align='center'
            gutterBottom
          >
            All available brands
          </Typography>
          <DividerMui5 />
        </>
      )}
      {showSubTitle && (
        <Subheader variant='h2' color='textSecondary' align='center'>
          Shop other brands
        </Subheader>
      )}
      <Grid
        container
        spacing={1}
        mt={1}
        justifyContent={'center'}
        justifyItems={'center'}
      >
        {fullList.slice(0, listLength).map((brand) => (
          <Grid
            item
            lg={4}
            md={6}
            sm={6}
            xs={6}
            key={brand.name}
            display={'grid'}
            justifyItems={'center'}
          >
            <CardActionArea
              component={NextLinkMui5}
              href={`/makes/${brand.name}`}
              disableRipple
              sx={{
                textAlign: 'center',
                width: isExtraSmallScreen ? 174 : 394,
                height: isExtraSmallScreen ? 91 : 201,
              }}
            >
              <Image
                width={isExtraSmallScreen ? 174 : 394}
                height={isExtraSmallScreen ? 91 : 201}
                alt={brand.name}
                src={brand.src}
                quality={100}
                loading='lazy'
              />
            </CardActionArea>
          </Grid>
        ))}
      </Grid>
      <ShowMoreButton
        onClick={(expanded) => {
          setListLength(!expanded ? undefined : shortListLength);
        }}
      />
    </Box>
  );
};
