import { faInstagram } from '@fortawesome/free-brands-svg-icons/faInstagram';
import { faTiktok } from '@fortawesome/free-brands-svg-icons/faTiktok';
import { faYoutube } from '@fortawesome/free-brands-svg-icons/faYoutube';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { StandardLonghandProperties } from 'csstype';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { Component, FC, useEffect, useState } from 'react';
import {
  InstagramEmbed,
  TikTokEmbed,
  YouTubeEmbed,
} from 'react-social-media-embed';
import { StyledFontAwesomeIconMui5 } from '../../../../components/atoms/FontAwesomeIcon';
import Mui5Link from '../../../../components/atoms/Link';
import NextLinkMui5 from '../../../../components/atoms/NextLink';
import { useScreenSize } from '../../../../hooks/useScreenSize';
import { useSwiperReactive } from '../MediaMentions/hooks/useSwiperReactive';
import { PlatformIcon } from './constants';

type MediaItemProps = {
  name: string;
  description: string;
  video: string;
  platform: PlatformIcon;
  fallbackImage: string;
  index: number;
};

const MediaWrapper = styled(Paper)(() => ({
  width: '210px',
  height: '374px',
  overflow: 'hidden',
  borderRadius: '20px',
}));

const MediaIcon = styled('div')(() => ({
  position: 'absolute',
  top: 0,
  right: 0,
  width: '21px',
  height: '21px',
  positions: 'relative',
  '& svg': {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 'calc(100% - 4px)',
    height: 'calc(100% - 4px)',
  },
}));

const MediaContainer = styled('div', {
  shouldForwardProp: (prop) => prop !== 'isActive' && prop !== 'relativeIndex',
})<{
  isActive: boolean;
  relativeIndex: number;
}>(({ theme, isActive, relativeIndex }) => {
  let offset: StandardLonghandProperties['left'] = '0';
  if (relativeIndex < 0) {
    offset = theme.spacing(-5);
  } else if (relativeIndex > 0) {
    offset = theme.spacing(5);
  }
  return {
    height: 'fit-content',
    width: 'min-content',
    position: 'relative',
    [theme.breakpoints.down('md')]: {
      transform: `translateX(${offset})`,
    },
    ...(!isActive && {
      pointerEvents: 'none',
      filter: 'grayscale(0.5) contrast(50%)',
      opacity: 0.5,
    }),
    transition: theme.transitions.create(['filter', 'transform', 'opacity'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.short,
    }),
  };
});

const InstagramIframeTransformFit = styled('div')(() => ({
  position: 'relative',
  width: '100%',
  height: '100%',
  '& .rsme-embed.rsme-instagram-embed': {
    position: 'absolute !important',
    top: '50% !important',
    left: '50% !important',
    transform: 'translate(-50%, -50%) scale(0.63) !important',
  },
}));

const PlatformIconComponent: FC<Pick<MediaItemProps, 'platform'>> = ({
  platform,
}) => {
  switch (platform) {
    case PlatformIcon.Instagram:
      return <StyledFontAwesomeIconMui5 icon={faInstagram} color='#F708FF' />;
    case PlatformIcon.TikTok:
      return <StyledFontAwesomeIconMui5 icon={faTiktok} color='#00E3DC' />;
    case PlatformIcon.Youtube:
      return <StyledFontAwesomeIconMui5 icon={faYoutube} color='#F22F2F' />;
    default:
      return null;
  }
};

type FallbackComponentProps = Pick<
  MediaItemProps,
  'video' | 'fallbackImage' | 'platform'
>;

const InstagramErrorPreview: FC<FallbackComponentProps> = ({
  video,
  platform,
  fallbackImage,
}) => {
  return (
    <Box
      width='100%'
      height='100%'
      display='flex'
      justifyContent='center'
      alignItems='center'
    >
      <Box zIndex={1} p={1} position='relative'>
        <Typography
          variant='body1'
          color='textPrimary'
          align='center'
          gutterBottom
          sx={{ backgroundColor: 'rgba(255, 255, 255, 0.8)' }}
        >
          {platform} embed preview is not available
        </Typography>
        <Typography
          variant='body1'
          align='center'
          gutterBottom
          sx={{ backgroundColor: 'rgba(255, 255, 255, 0.8)' }}
        >
          <Mui5Link
            component={NextLinkMui5}
            href={video}
            // @ts-expect-error some weird component types issue
            target='_blank'
            rel='noopener noreferrer'
          >
            <PlatformIconComponent platform={platform} /> Watch on {platform}
          </Mui5Link>
        </Typography>
      </Box>
      <Box
        position='absolute'
        width='107%'
        height='107%'
        top='50%'
        left='50%'
        sx={{ transform: 'translate(-50%, calc(-50% + 5px))' }}
      >
        {fallbackImage && (
          <Image
            src={fallbackImage}
            alt={video}
            objectFit='contain'
            objectPosition='center'
            layout='fill'
            sizes='400px'
            quality={90}
          />
        )}
      </Box>
    </Box>
  );
};

const ErrorBoundaryWithRouter: FC<FallbackComponentProps> = (props) => {
  const router = useRouter();
  return <ErrorBoundaryWithDataDogFix {...props} router={router} />;
};

class ErrorBoundaryWithDataDogFix extends Component<
  FallbackComponentProps & { router: ReturnType<typeof useRouter> }
> {
  state = { error: null };

  static getDerivedStateFromError(error) {
    return { error };
  }

  render() {
    const { error } = this.state;
    const { platform, fallbackImage, video, router } = this.props;

    if (error || 'datadog' in router.query) {
      return (
        <InstagramErrorPreview
          video={video}
          platform={platform}
          fallbackImage={fallbackImage}
        />
      );
    }
    return this.props.children;
  }
}

export const MediaItem: FC<MediaItemProps> = ({
  name,
  description,
  video,
  platform,
  fallbackImage,
  index,
}) => {
  const swiper = useSwiperReactive();
  const isActive = swiper?.realIndex === index;

  let relativeIndex = index - swiper?.realIndex;
  if (relativeIndex > Math.floor(swiper?.slides.length / 2)) {
    relativeIndex = relativeIndex - swiper?.slides.length;
  } else if (relativeIndex < -Math.floor(swiper?.slides.length / 2)) {
    relativeIndex = relativeIndex + swiper?.slides.length;
  }

  const { isExtraSmallScreen, isSmallScreen } = useScreenSize();
  const isCompact = isExtraSmallScreen || isSmallScreen;

  const iconElement = <PlatformIconComponent platform={platform} />;
  let mediaElement: JSX.Element = null;
  switch (platform) {
    case PlatformIcon.Instagram:
      mediaElement = (
        <InstagramEmbed
          url={video}
          captioned={false}
          width='fit-content'
          height={isCompact ? '550px' : 'fit-content'}
          placeholderSpinnerDisabled={true}
        />
      );
      break;
    case PlatformIcon.TikTok:
      mediaElement = <TikTokEmbed url={video} width='210px' height='374px' />;
      break;
    case PlatformIcon.Youtube:
      mediaElement = (
        <SafeYoutubeContainer video={video} fallbackImage={fallbackImage} />
      );
      break;
  }

  if (platform !== PlatformIcon.Youtube) {
    mediaElement = (
      <InstagramIframeTransformFit>
        <ErrorBoundaryWithRouter
          fallbackImage={fallbackImage}
          video={video}
          platform={platform}
        >
          {mediaElement}
        </ErrorBoundaryWithRouter>
      </InstagramIframeTransformFit>
    );
  }

  return (
    <MediaContainer isActive={isActive} relativeIndex={relativeIndex}>
      <MediaWrapper elevation={4}>{mediaElement}</MediaWrapper>
      <Box mt={1}>
        <Typography variant='body1' sx={{ position: 'relative' }}>
          <b>{name}</b>
          <MediaIcon>{iconElement}</MediaIcon>
        </Typography>
        <Typography variant='body2' color='textSecondary'>
          {description}
        </Typography>
      </Box>
    </MediaContainer>
  );
};

export const SafeYoutubeContainer: FC<{
  video: string;
  fallbackImage: string;
}> = ({ video, fallbackImage }) => {
  const [failed, setFailed] = useState(false);
  const [timer, setTimer] = useState<NodeJS.Timeout | null>(null);

  useEffect(() => {
    const timer = setTimeout(() => {
      setFailed(true);
    }, 120000); // 120 seconds

    setTimer(timer);

    return () => clearTimeout(timer);
  }, []);

  if (failed) {
    return (
      <InstagramIframeTransformFit>
        <InstagramErrorPreview
          video={video}
          platform={PlatformIcon.Youtube}
          fallbackImage={fallbackImage || ''}
        />
      </InstagramIframeTransformFit>
    );
  }

  return (
    <InstagramIframeTransformFit>
      <ErrorBoundaryWithRouter
        fallbackImage={fallbackImage}
        video={video}
        platform={PlatformIcon.Youtube}
      >
        <YouTubeEmbed
          url={video}
          youTubeProps={{
            onReady: () => {
              clearTimeout(timer);
            },
            onError: () => {
              setFailed(true);
            },
            loading: 'lazy',
          }}
          width='210px'
          height='374px'
          placeholderSpinnerDisabled={true}
        />
      </ErrorBoundaryWithRouter>
    </InstagramIframeTransformFit>
  );
};
